<template>
  <el-dialog
    custom-class="ql-dialog single-upgrade-dialog"
    title="升级"
    :visible.sync="dialogVisible"
    width="680px"
    @close="handleCancel"
  >
    <div v-if="dialogPager === STEP_PAGER_ONE">
      <div class="container">
        <p class="tip">请选择升级的教室</p>
        <el-table
          :data="schoolList.filter((data) => !searchKey || data.name.toLowerCase().includes(searchKey.toLowerCase()))"
          @selection-change="handleSelectedChange"
        >
          <el-table-column type="selection" width="55"> </el-table-column>
          <el-table-column label="学校" prop="schoolName"> </el-table-column>
          <el-table-column label="版本" prop="versionName"> </el-table-column>
        </el-table>
      </div>
      <div class="footer">
        <el-button type="primary" size="small" style="margin-left: 10px" @click="handlePager(STEP_PAGER_TWO)"
          >下一步</el-button
        >
        <el-button class="mg-r-10" size="small" @click="handlePager(STEP_PAGER_ONE)">上一步</el-button>
      </div>
    </div>
    <div v-if="dialogPager === STEP_PAGER_TWO">
      <div class="container">
        <div>
          <p class="label mg-b-4">青鹿盒子升级版本</p>
          <p class="tip mg-b-16">当前青鹿盒子版本：</p>
          <el-select v-model="classVersionValue" placeholder="请选择">
            <el-option
              v-for="item in classVersionList"
              :key="item.versionCode"
              :label="item.versionName"
              :value="item.versionCode"
            >
            </el-option>
          </el-select>
        </div>
        <div class="mg-t-24">
          <p class="label mg-b-16">强制升级时间</p>
          <!-- time picker -->
          <el-date-picker
            v-model="compelUpdateTime"
            type="datetime"
            placeholder="选择"
            value-format="yyyy-MM-dd HH:mm"
            format="yyyy-MM-dd HH:mm"
            :picker-options="pickerOptions"
          >
          </el-date-picker>
        </div>
      </div>
      <span class="footer">
        <el-button type="primary" size="small" style="margin-left: 10px" @click="handleSubmit">升 级</el-button>
        <el-button v-if="isLocal !== PUBLIC_SERVER" size="small" @click="handlePager(STEP_PAGER_TWO)">上一步</el-button>
      </span>
    </div>
  </el-dialog>
</template>

<script>
/**
 * 批量升级 dialog
 */
// api
import { getRegisterClassListVersions } from '@/api/back-stage';
// component
import { Form, FormItem, Dialog, Button, Input, Radio, RadioGroup, Select, Option, DatePicker } from 'element-ui';
// const
import { LOCAL_SERVER, PUBLIC_SERVER, CLASS_STATUS, SCHOOL_STATUS } from '../constance';

const STEP_PAGER_ONE = 2;
const STEP_PAGER_TWO = 3;

export default {
  components: {
    [Form.name]: Form,
    [FormItem.name]: FormItem,
    [Dialog.name]: Dialog,
    [Button.name]: Button,
    [Input.name]: Input,
    [Radio.name]: Radio,
    [RadioGroup.name]: RadioGroup,
    [Select.name]: Select,
    [Option.name]: Option,
    [DatePicker.name]: DatePicker,
  },
  props: {
    visible: {
      type: Boolean,
      required: true,
      default: false,
    },
    isLocal: {
      type: Number,
      required: false,
      default: PUBLIC_SERVER,
    },
    schoolVersionCode: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      // const
      LOCAL_SERVER,
      PUBLIC_SERVER,
      CLASS_STATUS,
      SCHOOL_STATUS,
      STEP_PAGER_ONE,
      STEP_PAGER_TWO,

      dialogVisible: false,
      dialogPager: STEP_PAGER_ONE,

      // pager STEP_PAGER_ONE
      schoolList: [], // 学校列表
      searchKey: '', // 筛选
      selectedSchoolList: [], // 勾选的学校

      // pager STEP_PAGER_TWO
      classVersionValue: undefined,
      classVersionList: [], // 盒子版本 列表
      compelUpdateTime: undefined, // 强制升级时间

      pickerOptions: {
        disabledDate: function (date) {
          return date.getTime() < Date.now() - 24 * 60 * 60 * 1000;
        },
      },
    };
  },

  methods: {
    // handle 翻页
    handlePager(pager) {
      switch (pager) {
        case STEP_PAGER_ONE:
          this.getSchoolList();
          break;
        case STEP_PAGER_TWO:
          this.getClassVersionList();
          break;
        default:
          break;
      }
      this.dialogPager = pager;
    },
    // handle 取消
    handleCancel() {
      this.$emit('close');
    },
    handleSelectedChange(val) {
      this.selectedSchoolList = val;
    },
    // handle 升级
    handleSubmit() {
      // to do
      // validate
      let postData = {};
      if (this.isLocal === PUBLIC_SERVER) {
        // 公共服务器
        postData = {
          schoolKeys: '',
          compelUpdateTime: '',
          classDownloadUrl: '',
          classVersionCode: '',
        };
      } else if (this.isLocal === LOCAL_SERVER) {
        // 本地服务器
      }
      console.log(postData);
    },
    // api pager STEP_PAGER_TWO: 获取盒子版本列表
    getClassVersionList() {
      getRegisterClassListVersions({
        schoolVersionCode: getSchoolVersionCode(), // 最高学校版本号
        classVersionCode: getClassVersionCode(), // 最高盒子版本号
      }).then(({ result }) => {
        console.log(result);
      });

      // 获取 最高学校版本号
      function getSchoolVersionCode() {
        if (this.isLocal === PUBLIC_SERVER) {
          // 公共服务器升级时,传云平台的版本号
          return this.schoolVersionCode;
        } else {
          // 本地服务器批量升级时
          // 如果在升级后台时选择不升级,则传被勾选的学校的最高版本号
          // 如果勾选了后台升级的版本号,则将该版本号与被勾选的学校的最高版本号进行比较取最大值

          let highestVersionCode =
            this.schoolVersionValue !== 'dontUpgrate' ? (highestVersionCode = this.schoolVersionValue) : undefined;

          let schoolList = this.selectedSchoolList.filter((x) => x.classStatus !== CLASS_STATUS.UNINITIALIZED);
          schoolList.map((schoolItem) => {
            if (highestVersionCode === undefined) {
              // 第一个
              highestVersionCode = schoolItem.versionCode;
            } else if (highestVersionCode < schoolItem.versionCode) {
              highestVersionCode = schoolItem.versionCode;
            }
          });

          return highestVersionCode;
        }
      }

      // 获取 最高盒子版本号
      function getClassVersionCode() {
        let highestVersionCode = undefined;

        let schoolList = this.selectedSchoolList.filter((x) => x.classStatus !== CLASS_STATUS.UNINITIALIZED);
        schoolList.map((schoolItem) => {
          schoolItem.registerClasses.map((classItem) => {
            if (highestVersionCode === undefined) {
              // 第一个
              highestVersionCode = classItem.versionCode;
            } else if (highestVersionCode < classItem.versionCode) {
              highestVersionCode = classItem.versionCode;
            }
          });
        });
      }
    },
  },
  watch: {
    visible: {
      handler: function (val) {
        if (val) {
          // reset
          Object.assign(this.$data, this.$options.data());

          if (this.isLocal === PUBLIC_SERVER) {
            // 公共服务器
            this.dialogPager = STEP_PAGER_TWO;
            // todo
            // this.getClassVersionList();
          } else {
            // 本地服务器
            this.getSchoolVersionList();
          }
        }
        this.dialogVisible = val;
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.single-upgrade-dialog {
  .container {
    .tip {
      font-weight: 400;
      color: #8b8f8f;
      line-height: 24px;
    }
    .label {
      font-size: 16px;
      font-weight: 400;
      color: #2c2f2e;
      line-height: 22px;
    }
  }
  .footer {
    display: flex;
    margin-top: 48px;
    flex-direction: row-reverse;
  }
}
</style>
