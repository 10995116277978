<template>
  <div class="remote-upgrade-detail">
    <div class="header">
      <div class="button-back" @click="handleBack()">返回</div>
      <div class="name">{{ detailData.schoolName }}</div>
      <template>
        <span v-if="[0, 2, 4, null].includes(detailData.operation)">--</span>
        <el-button v-if="[1].includes(detailData.operation)" type="primary" size="small" @click="handleUpgrade"
          >修改</el-button
        >
        <el-button v-if="[3, 5].includes(detailData.operation)" type="primary" size="small" @click="handleUpgrade"
          >升级</el-button
        >
      </template>
    </div>
    <div v-if="isLocal === LOCAL_SERVER" class="info flex flex-1 items-center mg-t-24">
      <span style="color: #2c2f2e">后台版本：</span>
      <span style="color: #6d7171" class="schoolDetail-info-name">{{ detailData.versionName || '--' }}</span>
      <span class="mg-l-16 mg-r-16" style="color: #2c2f2e">|</span>
      <span style="color: #2c2f2e">版本说明：</span>
      <span style="color: #6d7171">{{ detailData.description || '--' }}</span>
    </div>
    <div v-if="detailData.registerClasses.length" class="list mg-t-24">
      <div
        v-for="item in detailData.registerClasses"
        :key="item.id"
        class="list-card"
        :class="{ isFail: item.status === 0 }"
      >
        <div class="list-card-header">
          <div class="place">{{ item.place }}</div>
          <div class="button" @click="handleDel(item)">删除</div>
        </div>
        <div class="list-card-container">
          <div class="td" style="width: 200px">
            <div class="label">IP地址</div>
            <div class="content">{{ item.serverUrl || '--' }}</div>
          </div>
          <div class="td" style="width: 100px">
            <div class="label">盒子版本</div>
            <div class="content">{{ item.versionName || '--' }}</div>
          </div>
          <div class="td" style="width: 520px">
            <div class="label">版本说明</div>
            <div class="content">{{ item.description || '--' }}</div>
          </div>
          <div class="td" style="width: 120px">
            <div class="label">更新时间</div>
            <div class="content">{{ item.updateTime || '--' }}</div>
          </div>
          <div class="td" style="width: 90px">
            <div class="label">版本状态</div>
            <div class="content">
              <template>
                <!-- null. 未初始化 8. 未初始化  -->
                <span v-if="[null, 8].includes(item.status)" class="status c16">未初始化</span>
                <!-- 1. 升级成功  -->
                <span v-if="item.status === 1" class="status c1">升级成功</span>
                <!-- 2. 升级中  -->
                <span v-if="item.status === 2" class="status c10">升级中</span>
                <!-- 5. 不兼容 6. 待升级  -->
                <span v-if="[5, 6].includes(item.status)" class="status c14">待升级</span>
                <!-- 7. 已是最新 -->
                <span v-if="item.status === 7" class="status c1">已是最新</span>
                <!-- 0. 升级失败  -->
                <el-popover v-if="item.status === 0" placement="top-start" trigger="hover">
                  <div class="flex">
                    <div class="title">{{ item.place }}：</div>
                    <div class="content">{{ item.message }}</div>
                  </div>
                  <span slot="reference" class="status c12">升级失败</span>
                </el-popover>
              </template>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else><noData></noData></div>
  </div>
</template>

<script>
/**
 * 远程升级 detail
 */
import { Button } from 'element-ui';
import noData from '@/components/noData/index.vue';

import { PUBLIC_SERVER, LOCAL_SERVER } from '../../constance';

import { getListRegisterClassBySchoolKey, removeRegisterClass } from '@/api/back-stage';

export default {
  name: 'remote-upgrade-detail',
  components: {
    [Button.name]: Button,
    noData,
  },
  props: {
    schoolKey: {
      type: String,
      require: false,
      default: '',
    },
  },
  data() {
    return {
      PUBLIC_SERVER,
      LOCAL_SERVER,

      isLocal: Number(sessionStorage.getItem('remote_upgrade_is_local')),

      detailData: {
        operation: null,
        schoolName: '',
        versionName: '',
        description: '',
        registerClasses: [
          // {
          //   id: '1',
          //   place: '101教室',
          //   serverUrl: '棠德南小学北校区',
          //   versionName: 'v1.2.0',
          //   description: '该版本优化了使用方式，修复在属性窗口弹出时，保存文件闪退。更新了xx接口。',
          //   updateTime: '2020年9月24日',
          // },
        ],
      },
    };
  },
  methods: {
    // handle 返回
    handleBack() {
      this.$emit('back');
    },
    // handle 删除
    handleDel(serverName) {
      this.$msgbox
        .confirm(`确定删除该教室？删除后无法对其升级`, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        })
        .then(() => {
          removeRegisterClass({
            serverName: serverName,
          }).then(() => {
            this.$message({
              type: 'success',
              message: `已成功删除该教室`,
            });
            this.getData();
          });
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消操作',
          });
        });
    },
    handleUpgrade() {
      // todo
    },
    // api
    getData() {
      getListRegisterClassBySchoolKey({
        schoolKey: this.schoolKey,
      }).then((res) => {
        Object.assign(this.detailData, res.result);
        // Object.assign(this.detailData.registerClasses, this.$options.data().detailData.registerClasses);
      });
    },
  },
  watch: {
    schoolKey: {
      handler: function (val) {
        if (val !== '') {
          Object.assign(this.$data, this.$options.data()); // reset
          this.getData();
        }
      },
      immediate: true,
    },
  },
};
</script>

<style lang="scss" src="./detail.scss" scoped></style>
